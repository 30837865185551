import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import StudentAbsentCriteria from '@/shared/models/criteria/studentabsent'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import { loading, toast } from '@/shared/utils/UIHelper'
import { customDateFilter } from '@/shared/filters/ToCustomDate'
import { IsSuccessCode } from '@/shared/utils/API'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ReportStudentRepository from '../../../../shared/http/repositories/socialProject/report-student'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import download from 'downloadjs'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
  },

  data: () => ({
    emptyText: 'Nenhum registro encontrado',
    criteria: new StudentAbsentCriteria(),
    dateStartFormatted: null,
    dateEndFormatted: null,
    teachers: [],
    categories: [],
    modalities: [],
    localTrainings: [],
    absentStudents: [],
    totalRegister: 0,
    pagination: {
      count: 1,
      limit: 30,
      index: 1
    },
    fields: [
      {
        key: 'studentName',
        label: 'ALUNO',
        class: 'left-header-border text-center'
      },
      {
        key: 'totalStudentAbsent',
        label: 'FALTAS',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'totalStudentAbsentJustification',
        label: 'JUSTIFICADAS',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'localTrainingName',
        label: 'LOCAL DE TREINAMENTO',
        class: 'middle-header-border text-center'
      },
      {
        key: 'classModalityName',
        label: 'MODALIDADE',
        class: 'middle-header-border text-center'
      },
      {
        key: 'classCategoryName',
        label: 'CATEGORIA',
        class: 'middle-header-border text-center'
      },
      {
        key: 'teacherName',
        label: 'PROFESSOR',
        class: 'right-header-border text-center'
      }
    ]
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Relatórios',
            href: this.$router.resolve({ name: 'ReportStudentList' }).href
          },
          {
            text: 'Alunos Ausentes'
          }
        ]
      }
    }
  },

  filters: {
  },

  created() {
    this.getAllModalityByLocalTraining()
    this.getAllTeachers()
  },

  methods: {
    getAllModalityByLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar modalidades por local de treinamento', 'ERRO')
        })
    },

    onSearch() {
      if (this.criteria.dateStart == null) {
        toast.warning('Preencha uma data inicial', 'FILTRO INVÁLIDO')
        return
      }

      this.totalRegister = 0
      loading.push()
      ReportStudentRepository.GetAllStudentAbsent(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          this.absentStudents = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          this.totalRegister = res.data.count
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          this.totalRegister = 0
          toast.error('Erro ao carregar alunos ausentes', 'ERRO')
        })
    },

    downloadExcel() {
      loading.push()
      ReportStudentRepository.ExportExcelStudentAbsent(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    getAllTeachers() {
      TeacherRepository.GetAll()
        .then(response => {
          this.teachers = response.data.data
        })
        .catch(() => {
          toast('Não foi possível carregar os professores')
        })
    },

    onSelectLocalTraining() {
      let list = this.modalityLocalTraining

      if (this.criteria.localTrainingId) {
        const type = this.localTrainings.filter(localTraining => localTraining.id === this.criteria.localTrainingId)

        if (type.length > 0) {
          this.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(modalityLocal => modalityLocal.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality() {
      let list = this.modalityLocalTraining

      if (this.criteria.modalityId) {
        let type = this.modalities.filter(s => s.id === this.criteria.modalityId)

        if (type.length > 0) {
          this.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.localTrainings = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.localTrainings.filter(l => l.id === local.id).length === 0) {
          this.localTrainings.push(local)
        }
      }

      this.getAllCategories()
    },

    getAllCategories() {
      this.categories = []
      if (this.criteria.modalityId) {
        CategoryRepository.GetAllByModality(this.criteria.modalityId)
          .then(response => {
            this.categories = response.data.data
          })
          .catch(() => {
            toast('Não foi possível carregar as categorias')
          })
      }
    },

    formatDateStart(event) {
      if (!event) return
      this.criteria.dateStart = customDateFilter(event.currentTarget.value)
    },

    formatDateEnd(event) {
      if (!event) return
      this.criteria.dateEnd = customDateFilter(event.currentTarget.value)
    },

    onContextDateStart(ctx) {
      this.dateStartFormatted = ctx.selectedFormatted
    },

    onContextDateEnd(ctx) {
      this.dateEndFormatted = ctx.selectedFormatted
    },
  }
}
